import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  getProductArray,
  getProductStyles,
  getProductArrayPriceRange,
} from "../utilities/parsing_utilities";
import {
  updateStyleItemState,
  calculateAvailableVariants,
  calculateInitialBulkOrderingState,
  sortStyleItems,
  autoSelectSubsequentStylesIfOneProductMatches,
  autoDeselectSubsequentStyles
} from "../utilities/mutator_utilities";

import { isProductOrderable } from "../utilities/product_utilities";

export const orderingSlice = createSlice({
  name: "ordering",
  initialState: {
    product_name: "Product Name Placeholder",
    products: [],
    styles: [],
    style_images: false,
    style_sort_order: null,
    style_sort_order_pop: null,
    owner_code: null,
    owner_name: null,
    resize_url: null,
    size_breakpoints: null,
    srcset_widths: null,
    default_width: null,
    max_style_list_colors: 8,
    color_drawer_is_open: false,
    sort_by: 'relevance',
    bulk_sort_by: 'relevance',
    color_filter_text: '',
    bulk_filter_text: '',
    bulk_drawer_is_open: false,
    bulk_drawer_selected_style_item_ref:null,
    preselected_style_item:null,
    sortable_by_price: false,
    show_stock_message: true,
    specs_url: null,
    specs_drawer_is_open: false,
    specs_drawer_selected_product_code: null,
    hide_low_stock_icons: false,
    reduced_text: 'Reduced',
    reduced_text_long: 'Reduced'
    
  },
  reducers: {
    setProductName: (state, action) => {
      state.product_name = action.payload;
    },
    setProductStyles: (state, action) => {
      state.styles = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setStyleImages: (state, action) => {
      state.style_images = action.payload === true;
    },
    setOwnerCode: (state, action) => {
      state.owner_code = action.payload;
    },
    setOwnerName: (state, action) => {
      state.owner_name = action.payload;
    },
    setResizeURL: (state, action) => {
      state.resize_url = action.payload;
    },
    setSizeBreakPoints: (state, action) => {
      state.size_breakpoints = action.payload;
    },
    setSrcSetWidths: (state, action) => {
      state.srcset_widths = action.payload;
    },
    setDefaultWidth: (state, action) => {
      state.default_width = action.payload;
    },
    setStyleSortOrder: (state, action) => {
      if(action.payload)
      {
        state.style_sort_order = action.payload;
      }
    },
    setStyleSortOrderPop: (state, action) => {
      if(action.payload)
      {
        state.style_sort_order_pop = action.payload;
      }
    },
    setSortBy: (state,action) => {
      state.sort_by = action.payload;
    },
    setBulkSortBy: (state,action) => {
      state.bulk_sort_by = action.payload;
    },
    initialStyleItemSort: (state) => {
      sortStyleItems(state);
    },
    setSelectedStyleItem: (state, action) => {
      const style_ref_to_update = action.payload.style_ref;
      const style_item_ref_to_select = action.payload.style_item_ref;
      let is_first_style = false;
      // find matching style, if index of match is zero is_first_style is true
      const style_match = state.styles.find(
        (style,index) => {
            if(style.style_ref === style_ref_to_update)
            {
              if(index === 0)
              {
                is_first_style = true;
              }
              return true;
            }
            return false;
        }
      );
            
      if (style_match) {
        //Don't allow deselection of style with one style item
        if(style_match.style_items.length === 1 && style_item_ref_to_select === null)
        {
          return;
        }
        //Don't allow deselection of first style when there are style images
        if(state.style_images === true && is_first_style === true && style_item_ref_to_select === null)
        {
          return;
        }
        style_match.selected_style_item_ref = style_item_ref_to_select;
        style_match.style_items.forEach((style_item) => {
          style_item.selected =
            style_item.style_item_ref === style_item_ref_to_select;
        });
        if(style_item_ref_to_select === null)
        {
          autoDeselectSubsequentStyles(state,style_match.style_ref);
        }
        else
        {
          autoSelectSubsequentStylesIfOneProductMatches(state,style_match.style_ref);
        }
        updateStyleItemState(state);
      }
      
    },
    deselectStyle: (state, action) => {
      const style_ref_to_deselect = action.payload;
      const style_match = state.styles.find(
        (style) => style.style_ref === style_ref_to_deselect
      );
      if (style_match && style_match.style_items.length > 1) {
        style_match.selected_style_item_ref = null;
        style_match.style_items.forEach((style_item) => {
          style_item.selected = false;
        });
      }
      updateStyleItemState(state);
    },
    runStyleItemStateUpdate: (state) => {
      updateStyleItemState(state);
    },
    runAvailableSizesCalculation: (state) => {
      calculateAvailableVariants(state);
    },
    runInitialBulkOrderingStateCalculation: (state) => {
      calculateInitialBulkOrderingState(state);
    },
    dismissColorDrawer: (state) => {
      state.color_drawer_is_open = false;
    },
    openColorDrawer: (state) => {
      state.color_drawer_is_open = true;
    },
    setColorFilterText: (state,action) => {
      state.color_filter_text = action.payload;
    },
    setBulkFilterText: (state,action) => {
      state.bulk_filter_text = action.payload;
    },
    openBulkDrawer: (state,action) => {
      state.bulk_drawer_is_open = true;
    },
    dismissBulkDrawer: (state) => {
      state.bulk_drawer_is_open = false;
    },
    setBulkOrderDrawerSelectedStyleItemRef: (state,action) => {
      state.bulk_drawer_selected_style_item_ref = action.payload;
    },
    setPreSelectedStyleItem: (state,action) => {
      if(action.payload)
      {
        state.preselected_style_item = action.payload;
      }
    },
    setSortableByPrice : (state,action) => {
      state.sortable_by_price = action.payload === true;
    },
    setShowStockMessage : (state,action) => {
      state.show_stock_message = action.payload !== false;
    },
    setSpecsURL : (state,action) => {
      state.specs_url = action.payload;
    },
    openSpecsDrawer: (state) => {
      state.specs_drawer_is_open = true;
    },
    closeSpecsDrawer: (state) => {
      state.specs_drawer_is_open = false;
    },
    setSpecsDrawerSelectedProductCode: (state,action) => {
      state.specs_drawer_selected_product_code = action.payload;
    },
    setSelectionsForProductCode: (state,action) => {
      const selected_product = state.products.find(p => p.code === action.payload);
      if(selected_product)
      {        
        selected_product.styles.forEach(ps => {
          const style_match = state.styles.find(os => os.style_ref === ps.style_ref);
          style_match.selected_style_item_ref = ps.style_item_ref;
          style_match.style_items.forEach(si => {
            si.selected = si.style_item_ref === style_match.selected_style_item_ref;
          });
        })
      }
      updateStyleItemState(state);
    },
    setHideLowStockIcons: (state,action) => {
      state.hide_low_stock_icons = action.payload === true;
    },
    setReducedText: (state,action) => {
      if(action.payload)
      {
        state.reduced_text = action.payload;      
      }
    },
    setReducedTextLong: (state,action) => {
      if(action.payload)
      {
        state.reduced_text_long = action.payload;
      }
    }
  },
});

export const {
  setProductStyles,
  setProducts,
  setStyleImages,
  setOwnerCode,
  setOwnerName,
  setResizeURL,
  setSizeBreakPoints,
  setSrcSetWidths,
  setDefaultWidth,
  setStyleSortOrder,
  setStyleSortOrderPop,
  initialStyleItemSort,
  setSelectedStyleItem,
  deselectStyle,
  runStyleItemStateUpdate,
  runAvailableSizesCalculation,
  runInitialBulkOrderingStateCalculation,
  dismissColorDrawer,
  openColorDrawer,
  setSortBy,
  setBulkSortBy,
  setColorFilterText,
  setBulkFilterText,
  openBulkDrawer,
  dismissBulkDrawer,
  setBulkOrderDrawerSelectedStyleItemRef,
  setPreSelectedStyleItem,
  setSortableByPrice,
  setShowStockMessage,
  setSpecsURL,
  openSpecsDrawer,
  closeSpecsDrawer,
  setSpecsDrawerSelectedProductCode,
  setSelectionsForProductCode,
  setHideLowStockIcons,
  setReducedText,
  setReducedTextLong
} = orderingSlice.actions;

//DOM element to emit events
var app_container = null;

//THUNKS
export const initialize =
  ({ productJSON, appContainer, showStockMessage = true }) =>
  (dispatch) => {
    app_container = appContainer;

    const product_data = JSON.parse(productJSON);
    const product_array = getProductArray(product_data);
    const styles_array = getProductStyles(product_data);

    dispatch(setProducts(product_array));
    dispatch(setProductStyles(styles_array));
    dispatch(setStyleImages(product_data.style_images));
    dispatch(setShowStockMessage(showStockMessage));
    dispatch(setOwnerCode(product_data.owner_code));
    dispatch(setOwnerName(product_data.owner_name));
    dispatch(setResizeURL(product_data.resize_url));
    dispatch(setSizeBreakPoints(product_data.size_breakpoints));
    dispatch(setSrcSetWidths(product_data.srcset_widths));
    dispatch(setDefaultWidth(product_data.default_width));
    dispatch(setStyleSortOrder(product_data.style_sort_order));
    dispatch(setStyleSortOrderPop(product_data.style_sort_order_pop));
    dispatch(setPreSelectedStyleItem(product_data.preselected_style_item));
    dispatch(setSpecsURL(product_data.specs_url));
    dispatch(setHideLowStockIcons(product_data.hide_low_stock_icons));
    dispatch(setReducedText(product_data.reduced_text));
    dispatch(setReducedTextLong(product_data.reduced_text_long));
    dispatch(calculatePriceSortability());
    dispatch(initialStyleItemSort());
    dispatch(initializeStyleSelections());
    dispatch(runStyleItemStateUpdate());
    dispatch(runAvailableSizesCalculation());
    dispatch(runInitialBulkOrderingStateCalculation());
  };

export const calculatePriceSortability = () => (dispatch,getState) => {
  const state = getState();
  const styles = state.ordering.styles;
  
  let price_sortable = false;

  if(styles.length > 0)
  {
    let last_min_price = false;
    let last_max_price = false;
    const style_items = styles[0].style_items;
    for(let i = 0; i < style_items.length; i++)
    {
      if(i !== 0)
      {
        if((last_min_price !== style_items[i].min_price) || (last_max_price !== style_items[i].max_price))
        {
          price_sortable = true;  
          break;
        }
      }
      last_min_price = style_items[i].min_price;
      last_max_price = style_items[i].max_price;
    }
  }
  dispatch(setSortableByPrice(price_sortable));
}

export const fireUpdateEventForCurrentSelection =
  () => (dispatch, getState) => {
    const state = getState();
    const matches = selectCurrentMatchingProducts(state);
    const selected_price_range = selectCurrentPriceRange(state);
    const price_range = selectFullPriceRange(state);
    const all_selected = selectAllSelectionsMade(state);

    const event_data = {
      matches: matches,
      selected_price_range: selected_price_range,
      price_range: price_range,
      all_selected: all_selected
    };
    app_container.dispatchEvent(
      new CustomEvent("ordering:selectionUpdate", { detail: event_data })
    );
  };

export const bulkOrderAddToCart = (order_data) => (dispatch,getState) =>
{
  dispatch(dismissBulkDrawer());
  app_container.dispatchEvent(
    new CustomEvent("ordering:bulkAddToCart", { detail: order_data })
  );
}

export const bulkOrderStockNotifyClick = (notify_code) => (dispatch) =>
{
  dispatch(dismissBulkDrawer());
  app_container.dispatchEvent(
    new CustomEvent("ordering:stockNotify", { detail: notify_code })
  );
}

export const specsOrderingStockNotifyClick = (notify_code) => (dispatch,getState) =>
{
  const state = getState();
  if(state.ordering.specs_drawer_is_open)
  {
    dispatch(dismissSpecsOrdering());
  }  
  app_container.dispatchEvent(
    new CustomEvent("ordering:stockNotify", { detail: notify_code })
  );
}

export const bulkOrderLargeViewClick = (style_item_code) => (dispatch) =>
{
  app_container.dispatchEvent(
    new CustomEvent("ordering:bulkOrderLargeView", { detail: { style_item_code: style_item_code } })
  );
}


export const updateSelectedStyleItem =
  ({ style_ref, style_item_ref }) =>
  (dispatch, getState) => {
    //create selector functions with a specific context
    //usage: contextSpecificSelector(state)
    const styleIndexSelector = selectStyleIndex(style_ref);
    const styleItemSelector = selectSelectedStyleItem(style_ref);

    //dispatch state changes
    dispatch(setSelectedStyleItem({ style_ref, style_item_ref }));

    //get state after updates have been made
    const state_after_update = getState();
    
    //select relevant portions of state
    const color_drawer_is_open =  selectColorDrawerIsOpen(state_after_update);
    const style_index = styleIndexSelector(state_after_update);
    const is_color = selectStyleImages(state_after_update);

    //fire event if change is to an image related style
    if (is_color === true && style_index === 0) {
      const color_style_item = styleItemSelector(state_after_update);
      if(color_style_item)
      {
        const color_style_item_code = color_style_item.style_item_code;
        app_container.dispatchEvent(
          new CustomEvent("ordering:colorSelected", {
            detail: { style_item_code: color_style_item_code },
          })
        );
      }      
    }
    if(color_drawer_is_open)
    {
      dispatch(dismissColorDrawer());
    }
    dispatch(fireUpdateEventForCurrentSelection());
  };

export const setSelectedProductCode = (pcode) => (dispatch, getState) => 
{
  const initial_color_code = selectCurrentColorStyleItemCode(getState());
  dispatch(setSelectionsForProductCode(pcode));
  const updated_color_code = selectCurrentColorStyleItemCode(getState());

  if(updated_color_code && updated_color_code !== initial_color_code)
  {
    app_container.dispatchEvent(
      new CustomEvent("ordering:colorSelected", {
        detail: { style_item_code: updated_color_code },
      })
    );
  }
  if(selectColorDrawerIsOpen(getState()))
  {
    dispatch(dismissColorDrawer());
  }
  dispatch(fireUpdateEventForCurrentSelection());

}

export const initializeStyleSelections = () => (dispatch,getState) => {
  const state = getState();

  const first_style = state.ordering.styles[0];
  const preselected_color_code = state.ordering.preselected_style_item;

  //try to match preselected color
  if(preselected_color_code)
  {
    const matching_color_style_item = first_style.style_items.find(si => si.style_item_code === preselected_color_code);
    if(matching_color_style_item)
    {
      dispatch(updateSelectedStyleItem({
        style_ref : first_style.style_ref,
        style_item_ref : matching_color_style_item.style_item_ref
      }));
      //If matched return early.
      return; 
    }    
  }

  //if product has style images pre-select color of first orderable product.
  if(state.ordering.style_images === true)
  {
    const first_orderable_product = state.ordering.products.find(p => isProductOrderable(p));

    if(first_orderable_product && first_orderable_product.styles[0])
    {
      dispatch(updateSelectedStyleItem({
        style_ref : first_orderable_product.styles[0].style_ref,
        style_item_ref : first_orderable_product.styles[0].style_item_ref
      }));    
      return;
    }
    
    //Select first style item
    const first_style_item = first_style.style_items[0];
    dispatch(updateSelectedStyleItem({
      style_ref : first_style.style_ref,
      style_item_ref : first_style_item.style_item_ref
    }));
    return;
  }
  //No initial selections made, fire update event anyway
  dispatch(fireUpdateEventForCurrentSelection());
}

export const openBulkOrdering = style_item_ref => (dispatch) => {
  dispatch(setBulkOrderDrawerSelectedStyleItemRef(style_item_ref));
  dispatch(openBulkDrawer());
};

export const openSpecsOrdering = () => (dispatch,getState) => {
  const state = getState();
  const matches = selectCurrentMatchingProducts(state);
  const all_selections_made = selectAllSelectionsMade(state);
  const match_code = matches.length === 1 && all_selections_made ? matches[0].code : null;
  dispatch(setSpecsDrawerSelectedProductCode(match_code));
  dispatch(openSpecsDrawer());
}

export const openSpecsOrderingForProduct = (code) => (dispatch,getState) => {
  dispatch(setSpecsDrawerSelectedProductCode(code));
  dispatch(openSpecsDrawer());
}

export const dismissSpecsOrdering = () => (dispatch,getState) => {
  const state = getState();
  if(state.ordering.specs_drawer_selected_product_code)
  {
    dispatch(setSelectedProductCode(state.ordering.specs_drawer_selected_product_code));
  }
  dispatch(closeSpecsDrawer());
}

//Selectors
export const selectOwnerCode = (state) => state.ordering.owner_code;
export const selectOwnerName = (state) => state.ordering.owner_name;
export const selectStyles = (state) => state.ordering.styles;
export const selectStyleImages = (state) => state.ordering.style_images;
export const selectResizeURL = (state) => state.ordering.resize_url;
export const selectSizeBreakPoints = (state) => state.ordering.size_breakpoints;
export const selectSrcSetWidths = (state) => state.ordering.srcset_widths;
export const selectDefaultWidth = (state) => state.ordering.default_width;
export const selectMaxStyleListColors = (state) => state.ordering.max_style_list_colors;
export const selectColorDrawerIsOpen = (state) => state.ordering.color_drawer_is_open;
export const selectSortBy = (state) => state.ordering.sort_by;
export const selectBulkSortBy = (state) => state.ordering.bulk_sort_by;
export const selectSortOrderPop = (state) => state.ordering.style_sort_order_pop;
export const selectColorFilterText = (state) => state.ordering.color_filter_text;
export const selectBulkFilterText = (state) => state.ordering.bulk_filter_text;
export const selectPreSelectedStyleItem = (state) => state.ordering.preselected_style_item;
export const selectSortableByPrice = (state) => state.ordering.sortable_by_price;
export const selectShowStockMessage = (state) => state.ordering.show_stock_message;
export const selectSpecsURL = (state) => state.ordering.specs_url;
export const selectSpecsDrawerIsOpen = (state) => state.ordering.specs_drawer_is_open;
export const selectSpecsDrawerSelectedProductCode = (state) => state.ordering.specs_drawer_selected_product_code;
export const selectHideLowStockIcons = (state) => state.ordering.hide_low_stock_icons;
export const selectReducedText = (state) => state.ordering.reduced_text;
export const selectReducedTextLong  = (state) => state.ordering.reduced_text_long;


export const selectSpecsDrawerSelectedProduct = (state) => {
  return state.ordering.products.find(p => p.code === state.ordering.specs_drawer_selected_product_code);
}

export const selectCurrentColorStyleItemCode = (state) => {
  let current_color_code = null;
  if(state.ordering.style_images && state.ordering.styles[0])
  {
    const styleItemSelector = selectSelectedStyleItem(state.ordering.styles[0].style_ref);
    const selected_style_item = styleItemSelector(state);

    if(selected_style_item)
    {
      current_color_code = selected_style_item.style_item_code;
    }    
  }
  return current_color_code;
}




export const selectSelectedStyleItem = (style_ref) => (state) => {
  const style_match = state.ordering.styles.find(
    (style) => style.style_ref === style_ref
  );
  if (style_match && style_match.selected_style_item_ref) {
    return style_match.style_items.find((style_item) => style_item.style_item_ref === style_match.selected_style_item_ref);
  } else {
    return undefined;
  }
};

export const selectStyleIndex = (style_ref) => (state) => {
  return state.ordering.styles.findIndex(
    (style) => style.style_ref === style_ref
  );
};

export const selectCurrentMatchingProducts = (state) => {
  const matches = state.ordering.products.filter((p) => {
    let is_match = true;
    p.styles.forEach((s) => {
      const selectThisSelectedStyleItem = selectSelectedStyleItem(s.style_ref);
      let current_selection = selectThisSelectedStyleItem(state);
      if (
        current_selection &&
        current_selection.style_item_ref !== s.style_item_ref
      ) {
        is_match = false;
      }
    });
    return is_match;
  });
  return matches;
};

export const selectCurrentPriceRange = (state) => {
  const matching_products = selectCurrentMatchingProducts(state);
  if (matching_products.length > 0) {
    return getProductArrayPriceRange(matching_products);
  } else {
    return null;
  }
};

export const selectFullPriceRange = (state) => {  
  const all_products = state.ordering.products;
  return getProductArrayPriceRange(all_products);
};


export const selectAllSelectionsMade = (state) => {
  const unselected_styles = state.ordering.styles.filter(s => s.selected_style_item_ref === null);
  return unselected_styles.length === 0;
};

export const selectFirstStyle = (state) => {
  return state.ordering.styles[0];
};

export const selectFirstStyleItems = (state) => {
  return state.ordering.styles.length ? state.ordering.styles[0].style_items : [];
};

const outputSelectSortedStyleItems = (style, style_items, sort_by, sort_order_pop) => {
  //sort things and stuff
  const sort_result = [...style_items];

  if(sort_by === 'popular' && sort_order_pop)
  {
    const style_sort_order = sort_order_pop.styles.find(
      (style_sort) => style_sort.style === style.style_ref
    );
    if (style_sort_order?.items) {
      sort_result.sort((a, b) => {
        const a_sort_index = style_sort_order.items.findIndex(
          (sorted_style_item_ref) =>
            a.style_item_ref === sorted_style_item_ref
        );
        const b_sort_index = style_sort_order.items.findIndex(
          (sorted_style_item_ref) =>
            b.style_item_ref === sorted_style_item_ref
        );
        return a_sort_index - b_sort_index;
      });
    }
  }

  if(sort_by === 'a_to_z')
  {
    sort_result.sort((a, b) => {          
      const a_name = a.style_item_name;
      const b_name = b.style_item_name;
      return a_name.localeCompare(b_name);
    });
  }

  if(sort_by === 'z_to_a')
  {
    sort_result.sort((a, b) => {
      const a_name = a.style_item_name;
      const b_name = b.style_item_name;
      return b_name.localeCompare(a_name);
    });
    
  }

  if(sort_by === 'low_price')
  {
    sort_result.sort((a, b) => {
      const min_price_diff = a.min_price - b.min_price;
      const max_price_diff = a.max_price - b.max_price;
      return min_price_diff === 0 ? max_price_diff : min_price_diff;
    });
  }

  if(sort_by === 'high_price')
  {
    sort_result.sort((a, b) => {
      const min_price_diff = b.min_price - a.min_price;
      const max_price_diff = b.max_price - a.max_price;
      return max_price_diff === 0 ? min_price_diff : max_price_diff;
    });
  }    
  return sort_result;
}

const outputSelectFilteredStyleItems = (style_items, text_filter) => {
  if (!text_filter) {
    return [...style_items];
  }

  //break search string into word tokens
  const filter_words = text_filter.split(/(\s|\/)+/);
  
  //lowercase each word token
  filter_words.forEach((f_word, i) => {
    filter_words[i] = f_word.toLowerCase();
  });

  //filter: keep style items that have a word that begins with all of the filter words
  const matches = style_items.filter((si) => {
    
    //concatanate style item text to search with whitespace separator
    const style_item_text = si.style_item_name + " " + si.style_item_model;
    
    //trim text and break style item text into word tokens
    const style_item_words = style_item_text.trim().split(/(\s|\/|-|\\)+/);

    //lowercase each word token
    style_item_words.forEach((si_word, i) => {
      style_item_words[i] = si_word.toLowerCase();
    });

    //try to find a filter word that doesn't have a matching
    const non_matching_filter_word = filter_words.find((f_word) => {
      //find a matching style item name word
      const f_word_match = style_item_words.find((si_word) => {
        const si_start = si_word.substring(0, f_word.length);
        return si_start === f_word;
      });
      const no_si_match_found = !f_word_match;
      return no_si_match_found;
    });

    const all_filter_words_match = !non_matching_filter_word;
    return all_filter_words_match;
  });

  return matches;
}

//create a selector for getting style items in current sorted order for the first style
//intended use is for sorting the color drawer
export const selectFirstStyleItemsSorted = createSelector(
  //the input selectors
  //createSelector caches based on a combination of these selected state values
  selectFirstStyle,
  selectFirstStyleItems,
  selectSortBy,
  selectSortOrderPop,

  //output selector
  //uses the input selectors from above (selectFirstStyle, selectFirstStyleItems, selectSortBy, selectSortOrderPop )
  //the output is cached based on inputs which are selected from state
  //what a country!
  outputSelectSortedStyleItems

);

export const selectFirstStyleItemsFiltered = createSelector(
  selectFirstStyleItemsSorted,
  selectColorFilterText,
  outputSelectFilteredStyleItems
);

export const selectBulkFirstStyleItemsSorted = createSelector(
  //the input selectors
  //createSelector caches based on a combination of these selected state values
  selectFirstStyle,
  selectFirstStyleItems,
  selectBulkSortBy,
  selectSortOrderPop,

  //output selector
  outputSelectSortedStyleItems
  
);


export const selectBulkFirstStyleItemsFiltered = createSelector(
  selectBulkFirstStyleItemsSorted,
  selectBulkFilterText,
  outputSelectFilteredStyleItems
);


export const selectBulkOrderDrawerSelectedStyleItem = (state) => {  
  if(state.ordering.styles.length > 0 && state.ordering.bulk_drawer_selected_style_item_ref)
  {
    return state.ordering.styles[0].style_items.find(
      si => si.style_item_ref === state.ordering.bulk_drawer_selected_style_item_ref 
    )
  }
};

export const selectBulkOrderDrawerIsOpen = (state) => {
  return state.ordering.bulk_drawer_is_open;
};

export const selectAllProducts = (state) => {
  return state.ordering.products;
}

export const selectBulkOrderDrawerSelectedProducts = createSelector(
  selectBulkOrderDrawerSelectedStyleItem,
  selectAllProducts,
  (selected_style_item, products) => {
    if(selected_style_item)
    {
      return products.filter(p => {
        return p.styles.length > 0 && p.styles[0].style_item_ref === selected_style_item.style_item_ref;
      })
    }
    else
    {
      return [];
    }
  }
)

export const selectColorCount = state => state.ordering.styles.length > 0 ? state.ordering.styles[0].style_items.length: 0;


export default orderingSlice.reducer;

