import { useDispatch, useSelector } from "react-redux";
import { BulkOrderingThumb } from "./BulkOrderingThumb";
import classNames from "classnames";
import { openBulkOrdering, bulkOrderLargeViewClick, selectReducedText } from "../store/orderingSlice";
import { BulkOrderingAvailableVariants } from "./BulkOrderingAvailableVariants";

function BulkOrderingInfoTag({bestSeller = false , newItem = false, closeoutItem = false, saleItem = false, reducedItem = false})
{
  const reduced_text = useSelector(selectReducedText);
  if(reducedItem) {
    return <div className="color-browser-card__info-tag color-browser-card__info-tag--reduced-item">{reduced_text}</div>;
  }

  if(closeoutItem) {
    return <div className="color-browser-card__info-tag color-browser-card__info-tag--clearance-item">Clearance</div>;
  }

  if(saleItem) {
    return <div className="color-browser-card__info-tag color-browser-card__info-tag--sale-item">Sale</div>;
  }

  if(bestSeller) {
    return <div className="color-browser-card__info-tag color-browser-card__info-tag--best-seller">Best Seller</div>;
  }

  if(newItem) {
    return <div className="color-browser-card__info-tag color-browser-card__info-tag--new-item">New</div>;
  }
  
  return <></>;

}

export function BulkOrderingListCard({ styleItem }) {

  const dispatch = useDispatch();

  const column_wrapper_classes = [
    "col-6",
    "col-md-4",
    "col-xl-3",
    "pb-4"
  ];

  const column_wrapper_classname = classNames(column_wrapper_classes);

  const image_wrap_wrapper_classname = classNames({
    "color-browser-card__image-wrap": true,
    "color-browser-card__image-wrap--is-low-stock": styleItem?.bulk_ordering_state?.low_stock,
    "color-browser-card__image-wrap--is-backordered": styleItem?.bulk_ordering_state?.backordered
  });

  const image_classname = classNames({
    "color-browser-card__image": true,
    "color-browser-card__image--is-low-stock": styleItem?.bulk_ordering_state?.low_stock,
    "color-browser-card__image--is-backordered": styleItem?.bulk_ordering_state?.backordered
  });

  const onClick = () => {
    dispatch(openBulkOrdering(styleItem.style_item_ref));
  }

  const imageClick = (e) => {
    e.stopPropagation();
    dispatch(bulkOrderLargeViewClick(styleItem.style_item_code));
  }

  let price_text = "";

  if(styleItem.min_price === styleItem.max_price)
  {
    if(typeof styleItem.min_price !== "undefined")
    {
      price_text = '$' + styleItem.min_price.toFixed(2);
    }
  }
  else
  {
    price_text = '$' + styleItem.min_price.toFixed(2) + ' - $' + styleItem.max_price.toFixed(2);
  }

  return (
    <div className={column_wrapper_classname}>
      <div
        className="color-browser-card row"
        onClick={onClick}
      >

        <div className="color-browser-card__image-section col">
          <div
            className={image_wrap_wrapper_classname}
            onClick={imageClick}
          >
            <BulkOrderingThumb
              className={image_classname}
              styleItem={styleItem}
            />
            {styleItem?.bulk_ordering_state?.low_stock && 
              <span
                className="color-browser-card__item-image-icon color-browser-card__item-image-icon--is-low-stock"
                title="Low Stock"
              ></span>
            }
          </div>
        </div>

        <div className="color-browser-card__info-section col">
          <BulkOrderingInfoTag
            bestSeller={styleItem.best_seller}
            newItem={styleItem.new_item}
            closeoutItem={styleItem.closeout_item}
            saleItem={styleItem.sale_item}
            reducedItem={styleItem.reduced}
          />
          <div className="color-browser-card__name">{styleItem.style_item_name}</div>
          <div className="color-browser-card__model">{styleItem.style_item_model}</div>
          <div className="color-browser-card__price">{price_text}</div>
          {styleItem.available_variants?.length &&
            <BulkOrderingAvailableVariants 
              variants={styleItem.available_variants}
            />
          }
        </div>
        <button
          className="color-browser-card__button button-ghost button-ghost__small btn"
          type="button"          
        >Select Size</button>
      </div>
    </div>
  );
}
