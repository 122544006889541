import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectReducedText } from "../store/orderingSlice";

function ColorDrawerAvailableVariantsItem({ variant }) {
  const reduced_text = useSelector(selectReducedText)
  const class_name = classNames({
    "color-drawer__size": true,
    "color-drawer__size--is-backordered": variant.backordered,
    "color-drawer__size--is-low-stock": variant.low_stock,
    "color-drawer__size--is-reduced": variant.reduced,

  });

  return (
    <li className={class_name}>
      {variant.label}
      {(variant.low_stock || variant.reduced) && (
        <div className="color-drawer__size-icons">
          {variant.low_stock && (
            <span
              className="color-drawer__size-icon color-drawer__size-icon--is-low-stock"
              title="Low Stock"
            ></span>
          )}
          {variant.reduced && (
            <span
              className="color-drawer__size-icon color-drawer__size-icon--is-reduced"
              title={reduced_text}
            ></span>
          )}
        </div>        
      )}
      
    </li>
  );
}

export function ColorDrawerAvailableVariants({ variants }) {
  return (
    <>
      <div className="color-drawer__sizes-label">Available In:</div>
      <ul className="color-drawer__sizes-list">
        {variants.map((variant, index) => (
          <ColorDrawerAvailableVariantsItem key={index} variant={variant} />
        ))}
      </ul>
    </>
  );
}
